import React from "react";
import { Stack, Box } from "@mui/material";
import { Videocard, Channelcard, Loader } from "./";

const Videos = ({ videos, direction }) => {
  if(!videos?.length) return <Loader />;

  return (
    <Stack direction={direction ||"row"} flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start" gap={2}>
      {videos.map((item, idx) => (
        <Box key={idx}>
          {item.id.videoId && <Videocard video={item} />}
          {item.id.channelId && <Channelcard channelDetail={item} />}
        </Box>
      ))}
    </Stack>
  );
};

export default Videos;
