import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { FetchFromAPI } from "./utils/FetchFromAPI";
import { Channelcard, Videos } from ".";

const ChannelDetail = () => {
  const { id } = useParams();
  const [channelDetail, setchannelDetail] = useState(null);
  const [videos, setVideos] = useState([]);
  console.log(channelDetail, videos);

  useEffect(() => {
    FetchFromAPI(`channels?part=snippet&id=${id}`).then((data) =>
      setchannelDetail(data?.items[0])
    );
    FetchFromAPI(`search?channelId=${id}&part=snippet&order=date`).then(
      (data) => setVideos(data?.items)
    );
  }, [id]);

  return (
    <Box minHeight="95vh">
      <Box>
        <div
          style={{
            background:
              "radial-gradient(circle, rgba(243,21,3,1) 0%, rgba(0,0,0,1) 100%)",
              height:"300px",
              zIndex:"10"
          }}
        />
        <Channelcard channelDetail={channelDetail} marginTop="-100px"/>
      </Box>
      <Box display="flex" p="2">
          <Box sx={{mr: {sm: "100px"}}}/>
      <Videos videos={videos}/>
      </Box>
    </Box>
  );
};

export default ChannelDetail;
