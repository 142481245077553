import React from "react";
import { logo } from "../components/utils/constant";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";

const Navbar = () => (
  <Stack
  direction={{sx:"column", md:"row"}}
    alignItems="center"
    p={2}
    justifyContent={{sx:"center", md:"space-between"}}
    gap={2}
    sx={{
      position: "sticky",
      background: "#000",
      top: 0,
    }}
  >
    <Link to='/'
     style={{display:"flex", alignItems:"center"
     }}>
      <img src={logo} alt="logo" height={45} />
      <span style={{color:"white",
      fontWeight:"bold",
      marginLeft:"1rem",
      fontSize:"2.1rem"}}>Youtube v3</span>
     </Link>


     <SearchBar/>
  </Stack>
);

export default Navbar;
